/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/24/outline";
import Persona from "persona";

import Loader from "../../../components/Loader";
import API from "../../../api/api";
import { useAuth } from "../../../contexts/Auth";
import { PERSONA_ENVIRONMENT } from "../../../config";
import Terms from "../Terms";

type StepName = "terms" | "kyc";

type OnboardingStep = {
  id: number;
  name: StepName;
  status: string;
  displayName: string;
};

const NewOnboarding = ({
  setCompanyName,
}: {
  setCompanyName: Dispatch<SetStateAction<string | null>>;
}) => {
  const location = window.location.pathname.split("/")[2];
  const auth = useAuth();
  const [onboardingSteps, setOnboardingSteps] = useState<Array<OnboardingStep>>(
    [],
  );
  const [inquiryDetails, setInquiryDetails] = useState<
    { inquiryId: string; sessionToken: string } | undefined
  >(undefined);
  const [selectedStep, setSelectedStep] = useState<OnboardingStep | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const currentKycStatus = onboardingSteps.find(
    (step) => step.name === "kyc",
  )?.status;

  const acceptTerms = async ({ bridgeSignedAgreementId }) => {
    setLoading(true);
    const { data } = await API.post(
      `business/users/${auth.currentUser?.id}/terms`,
      {
        accountId: location,
        bridgeSignedAgreementId,
      },
    );
    if (data) {
      getOnboardingInfo();
    }
    setLoading(false);
  };

  const getKycInquiry = async () => {
    setLoading(true);
    const { data } = await API.post(
      `business/users/${auth.currentUser?.id}/kyc`,
      {
        accountId: location,
      },
    );
    data as { inquiryId: string; sessionToken: string };
    setInquiryDetails({
      inquiryId: data.inquiryId,
      sessionToken: data.sessionToken,
    });

    setLoading(false);
  };

  const setSelectedStepToFirstNotCompleted = (_steps: OnboardingStep[]) => {
    const firstNotCompleted = _steps.find(
      (step: OnboardingStep) => step.status !== "Approved",
    );
    setSelectedStep(firstNotCompleted);
  };

  const getOnboardingInfo = async () => {
    const url = `business/onboarding/${location}`;
    const { data } = await API.get(url);

    // null check against missing onboarding data from backend
    if (!data?.terms || !data?.walletScan || !data?.kyc) return null;

    // define onboarding steps
    const _steps = [
      {
        name: "terms" as StepName,
        displayName: "Terms",
        status: data.terms.status,
        id: 1,
      },
      {
        name: "kyc" as StepName,
        displayName: "Business Information",
        status: data.kyc.status,
        id: 2,
      },
    ];
    setCompanyName(data.name);
    setOnboardingSteps(_steps);
    setSelectedStepToFirstNotCompleted(_steps);
  };

  useEffect(() => {
    // use the id in the url to get the onboaridng steps for this
    getOnboardingInfo();
  }, []);

  useEffect(() => {
    if (selectedStep?.name === "kyc") {
      // get kyc inquiry information
      getKycInquiry();
    }
  }, [selectedStep]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <div className="p-6 h-auto col-span-2">
          <nav aria-label="Progress" className="mt-4">
            <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
              {onboardingSteps.map((step, stepIdx) => (
                <li key={stepIdx} className="relative md:flex md:flex-1">
                  {step?.status === "Approved" ? (
                    <div className="group flex w-full items-center">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-lavender-800">
                          <CheckIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-4 tex t-sm font-medium text-gray-900 capitalize">
                          {step.displayName}
                        </span>
                      </span>
                    </div>
                  ) : step.name === selectedStep?.name ? (
                    <>
                      <div
                        className="flex items-center px-6 py-4 text-sm font-medium"
                        aria-current="step"
                      >
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-lavender-800">
                          <span className="text-lavender-800">{step.id}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-lavender-800 capitalize">
                          {step.displayName}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="group flex items-center">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900">
                            {step.id}
                          </span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900 capitilize">
                          {step.displayName}
                        </span>
                      </span>
                    </div>
                  )}

                  {stepIdx !== onboardingSteps.length - 1 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div
                        className="absolute right-0 top-0 hidden h-full w-5 md:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
      <div className="p-6 col-span-2" style={{ minHeight: 700 }}>
        {selectedStep?.name === "terms" && <Terms onAccept={acceptTerms} />}
        {selectedStep?.name === "kyc" && inquiryDetails?.inquiryId && (
          <>
            {(currentKycStatus === "Not Started" ||
              currentKycStatus === "Pending" ||
              currentKycStatus === "NotStarted") && (
              <Persona.Inquiry
                inquiryId={inquiryDetails.inquiryId}
                environment={PERSONA_ENVIRONMENT}
                // only include sessionToken if it is defined
                {...(inquiryDetails?.sessionToken && {
                  sessionToken: inquiryDetails?.sessionToken,
                })}
                onLoad={() => {
                  setLoading(false);
                }}
                onComplete={async ({ inquiryId }) => {
                  await API.patch(
                    `business/users/${auth.currentUser?.id}/kyc`,
                    { inquiryId },
                  );
                  getOnboardingInfo();
                  // persona widget invokes this callback multiple times
                  // this causes event stream version mismatch conflicts
                  // so, until persona fixes the callback, use this React workaround
                }}
                onError={(err) => console.error(err)}
              />
            )}
            {currentKycStatus === "In_Review" && (
              <>
                <div>
                  Thank you for your submission, Your KYB is under review
                </div>
              </>
            )}
          </>
        )}
        {loading && <Loader />}
      </div>
    </div>
  );
};

export default NewOnboarding;
