import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";

import Dialog from "./Dialog";
import { useToast } from "../contexts/ToastContext";
import { useAuth } from "../contexts/Auth";
import API from "../api/api";
import { fiatAmountRegex } from "../utils/regex";

const CreateOnDemandTransactionModal = ({
  open,
  setOpen,
  setClose,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setClose: () => void;
}) => {
  const [idempotencyKey, setIdempotencyKey] = useState<string>(uuid());
  const [amount, setAmount] = useState("0");
  const [description, setDescription] = useState("");
  const [paymentInfoId, setPaymentInfoId] = useState("");
  const [bankAccounts, setBankAccounts] = useState([]);
  const [transactionType] = useState("Payment");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const isSubmitting = useRef(false);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const auth = useAuth();
  const accountId = auth?.currentUser?.selectedAccount?.accountId;

  useEffect(() => {
    const getBankAccounts = async () => {
      const { data } = await API.get(`business/bankAccounts/${accountId}`);
      setBankAccounts(data);
    };
    getBankAccounts();

    return () => {
      setBankAccounts([]);
    };
  }, [accountId]);

  useEffect(() => {
    const disable =
      !transactionType ||
      (!amount && Number(amount) !== 0) ||
      !paymentInfoId ||
      submitting;
    setDisableSubmit(disable);
  }, [transactionType, amount, paymentInfoId, submitting]);

  const getBankAccountDisplayName = (accountId: any) => {
    const account = bankAccounts?.find((account) => account?.id === accountId);
    return `Account ${account?.last4} (${
      account.type === "financial" ? "ACH" : account.type
    }) - ${account?.description || account?.bankName}`;
  };

  const debouncedSubmit = debounce(async () => {
    if (isSubmitting.current) return;

    try {
      isSubmitting.current = true;
      setSubmitting(true);

      await API.post(`business/${accountId}/transactions/onDemand`, {
        amount,
        paymentInfoId,
        description,
        transactionType,
        idempotencyKey,
      });

      handleClose();
    } catch (e: any) {
      showToast(e?.message ?? e);
    } finally {
      isSubmitting.current = false;
      setSubmitting(false);
    }
  }, 1000);

  const handleSubmit = () => {
    if (disableSubmit) return;
    debouncedSubmit();
  };

  const handleClose = () => {
    debouncedSubmit.cancel();

    setAmount("0");
    setDescription("");
    setIdempotencyKey(uuid());
    setPaymentInfoId("");
    if (setClose) setClose();
  };

  return (
    <Dialog open={open} setOpen={setOpen} setClose={handleClose}>
      <div className="mt-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex-grow text-center">
            <h4 className="text-2xl leading-8 text-gray-900">
              Start a transaction
            </h4>
          </div>
          <button className="p-2 absolute right-3 top-3" onClick={handleClose}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        <div className="py-2">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
          >
            Type
          </label>
          <div className="sm:mt-0 relative">
            <select
              name="transactionType"
              id="transactionType"
              autoComplete="off"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lavender-500 focus:outline-none focus:ring-lavender-500 sm:text-sm"
              value={"Payment"}
              onChange={(e) => {}}
              disabled={true}
            >
              <option>Payment</option>
            </select>
          </div>
        </div>
        <div className="py-2">
          <label
            htmlFor="bankAccount"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
          >
            Bank Account
          </label>
          <div className="sm:col-span-2 sm:mt-0">
            <select
              id="bankAccount"
              name="bankAccount"
              defaultValue=""
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lavender-500 focus:outline-none focus:ring-lavender-500 sm:text-sm"
              value={paymentInfoId}
              onChange={(e) => {
                if (e.target.value === "add") {
                  navigate("/connected-accounts#add-bank-account");
                } else {
                  setPaymentInfoId(e.target.value);
                }
              }}
            >
              <option value="">Select an account</option>
              {bankAccounts?.map((account, idx) => (
                <option value={account.id} key={`${idx}-${account.id}`}>
                  {getBankAccountDisplayName(account.id)}
                </option>
              ))}
              <option value="add">Add a payment method</option>
            </select>
          </div>
        </div>
        <div className="py-2">
          <label
            htmlFor="name"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
          >
            Amount
          </label>
          <div className="sm:mt-0 relative">
            <input
              type="text"
              name="amount"
              id="amount"
              autoComplete="off"
              placeholder="0.00"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-600 sm:text-sm sm:leading-6"
              value={amount}
              onChange={(e) => {
                // delete non-numeric chars
                const amount = e.target?.value.replace(/[^0-9.]/g, "");

                if (fiatAmountRegex.test(amount)) {
                  setAmount(amount);
                }
              }}
            />
            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-500">
              USD
            </span>
          </div>
        </div>
        <div className="py-2">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 text-left"
          >
            Description
          </label>
          <div className="sm:col-span-2 sm:mt-0">
            <input
              id="description"
              name="description"
              type="text"
              autoComplete="off"
              className="block w-full rounded-md border-0 py-1.5 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lavender-600 sm:text-sm sm:leading-6"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="py-2 flex flex-row-reverse flex-wrap">
          <button
            className={`ml-1 flex-1 w-full py-2.5 text-sm font-semibold text-white hover:bg-lavender-600 disabled:opacity-50 disabled:bg-lavender-200 disabled:text-lavender-700 bg-lavender-400 rounded-md flex justify-center items-center space-x-2 ${
              disableSubmit || isSubmitting.current
                ? "cursor-not-allowed"
                : "cursor-pointer hover:border-gray-600"
            } mt-6`}
            onClick={handleSubmit}
            disabled={disableSubmit || isSubmitting.current}
          >
            <span>{isSubmitting.current ? "Submitting..." : "Submit"}</span>
          </button>
          <button
            className="mt-6 mr-1 flex-1 w-full py-2.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 disabled:opacity-50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset text-black hover:bg-white rounded-md flex justify-center items-center space-x-2"
            onClick={handleClose}
            disabled={submitting}
          >
            <span>Cancel</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CreateOnDemandTransactionModal;
