import React, { useCallback } from "react";

import API from "../api/api";
import Terms from "./Onboarding/Terms";
import UnderReview from "./Onboarding/UnderReview";

const ExternalEmbeddedOutlet = () => {
  const [error] = React.useState<string | null>(null);
  const [accountId, setAccountId] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentStep, setCurrentStep] = React.useState<string | null>(null);

  const loadOnboarding = useCallback(
    async (acctId?: string) => {
      const response = await API.get(
        `accounts/${acctId || accountId}/onboarding`,
      );
      setCurrentStep(response.data.currentStep);
      setLoading(false);
      console.log(response.data);

      // get onboarding
    },
    [accountId],
  );

  // use effect link token make http call
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (!token) return;
    login(token).then((accountId) => {
      loadOnboarding(accountId);
    });
  }, [loadOnboarding]);

  const login = async (token: string) => {
    const { data } = await API.post(`accounts/login`, { linkToken: token });
    const { exp, accessToken, accountId } = data;
    setAccountId(accountId);
    localStorage.setItem("ansible-auth-exp", String(exp));
    localStorage.setItem("accessToken", accessToken);
    return accountId;
  };

  const acceptTerms = async () => {
    setLoading(true);
    const { data } = await API.post(`accounts/${accountId}/terms`, {
      accountId: accountId,
    });
    if (data) {
      loadOnboarding();
    }
    setLoading(false);
  };

  return (
    <div className="m-2">
      {error}
      {loading && "Loading..."}
      {currentStep === "ACCEPT_TERMS" && <Terms onAccept={acceptTerms} />}
      {currentStep === "PENDING_IDENTITY_VERIFICATION" && <UnderReview />}
    </div>
  );
};

export default ExternalEmbeddedOutlet;
